import React from 'react'

const FlowHomepage = () => (
    <div className="flowGraph">
        <div>
            <div className="iconWrap">
            <img
                className="icon"
                src="homeFlow/contract.svg"
                alt="contract"
                /></div>
            <p className="label">User A fill info</p>
        </div>
            <img
                className="arrow"
                src="homeFlow/arrow.svg"
                alt="arrow"
            />
        <div>
            <div className="iconWrap">
            <img
                className="icon"
                src="homeFlow/envelope.svg"
                alt="contract"
            /></div>
            <p className="label">Mail to User B</p>
        </div>
            <img
                className="arrow"
                src="homeFlow/arrow.svg"
                alt="arrow"
            />
        <div>
            <div className="iconWrap">
            <img
                className="icon"
                src="homeFlow/contract.svg"
                alt="contract"
            /></div>
            <p className="label">User B fill info</p>
        </div>
            <img
                className="arrow"
                src="homeFlow/arrow.svg"
                alt="arrow"
            />
        <div>
            <div className="iconWrap">
            <img
                className="icon"
                src="homeFlow/pdf.svg"
                alt="contract"
                /></div>
            <p className="label">Generate certified PDF</p>
        </div>
            <img
                className="arrow"
                src="homeFlow/arrow.svg"
                alt="arrow"
            />
        <div>
            <div className="iconWrap">
            <img
                className="icon"
                src="homeFlow/envelope.svg"
                alt="contract"
            /></div>
            <p className="label">Signature request</p>
        </div>
            <img
                className="arrow"
                src="homeFlow/arrow.svg"
                alt="arrow"
            />
        <div>
            <div className="iconWrap">
            <img
                className="icon"
                src="homeFlow/signature.svg"
                alt="contract"
            /></div>
            <p className="label">Signature on the platform</p>
        </div>
            <img
                className="arrow"
                src="homeFlow/arrow.svg"
                alt="arrow"
            />
        <div>
            <div className="iconWrap">
            <img
                className="icon"
                src="homeFlow/envelope.svg"
                alt="contract"
            /></div>
            <p className="label">Final PDF generation</p>
        </div>
    </div>
)

export default FlowHomepage