import React, { useState, useEffect } from 'react';
import debounce from '../services/Debounce.js'


import { connect } from 'react-redux';
import { useSpring, animated } from "react-spring";

function BackgroundParallax(props) {
    const [scrollY, setScrollY] = useState(0);
    
    useEffect(() => {
        const handleScroll = event => {
            setScrollY(window.scrollY)
        }
        window.addEventListener("scroll", debounce(handleScroll));
        return () => window.removeEventListener("scroll", debounce(handleScroll));
    },[]);

    const [{ springscrollY }, springsetScrollY] = useSpring(() => ({
        springscrollY: 0
      }));
    const parallaxLevel1 = 7
    const parallaxLevel2 = 7
    springsetScrollY({ springscrollY: scrollY });
    const shapeYCoord1 = springscrollY.interpolate(o => `translate3d(0, ${-o / parallaxLevel1}px, 0)`);
    const shapeYCoord2 = springscrollY.interpolate(o => `translate3d(0, ${-o / parallaxLevel2}px, 0)`);



    return (
        <>
            <animated.div  style={{ transform: shapeYCoord1, position: 'absolute',  left: '-710px', top: '280px', backfaceVisibility: 'hidden' }}>
                <span className="rectangle bg-rectangle rectangle-3 orange"></span>
            </animated.div>
            <animated.div  style={{ transform: shapeYCoord2, position: 'absolute', right: '1000px', top: '1700px', backfaceVisibility: 'hidden'}}>
                <span className="rectangle bg-rectangle rectangle-4 light-blue"></span>
            </animated.div>
            <animated.div  style={{ transform: shapeYCoord1, position:'absolute', top: '3000px', left: '-1000px', backfaceVisibility: 'hidden'}}>
                <span className="rectangle bg-rectangle rectangle-5 blue"></span>
            </animated.div>
            <animated.div style={{ transform: shapeYCoord2, position:'absolute', bottom: '320px', left: '-400px', backfaceVisibility: 'hidden'}}>
                <span className="rectangle bg-rectangle rectangle-6 light-blue"></span>
            </animated.div>
        </>
    )
}

function mapStateToProps(state) {
    return { stageData: state.stage };
}

export default connect(mapStateToProps)(BackgroundParallax)