import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { formItemFocus, formItemBlur } from '../redux';



function InputInfoContainer(props) {
    let [active, setActive] = useState(false)

    const iconClicked = () => {
        if (active)
            setActive(false)
        else 
            setActive(true)
    }

    useEffect(() => {
        if(props.placeholder.includes('&#10;'))
            props.placeholder.replace(/&#10;/g, /&#10;/)
    }, [props.placeholder])

    return (
    <div className={props.containerClass + (active ? ' active' : '')}>
        <div className={"icon-wrapper"  + (props.error ? ' error' : '') + (props.input === 'textarea' ? ' textarea' : '')}>
            { props.input === 'input' ? <input onFocus={() => {props.formItemFocus()}} onBlur={()  => {props.formItemBlur()}} onChange={props.handleFormChange} value={props.value} name={props.name} type={props.type} className={props.className} placeholder={props.placeholder}/> : null }
            { props.input === 'textarea' ? <textarea onFocus={() => {props.formItemFocus()}} onBlur={()  => {props.formItemBlur()}} onChange={props.handleFormChange} value={props.value} name={props.name} className={props.className} placeholder={props.placeholder} maxLength={props.maxLength}></textarea> : null}
            { props.input === 'number' ? <input onFocus={() => {props.formItemFocus()}} onBlur={()  => {props.formItemBlur()}} onChange={props.handleFormChange} value={props.value} name={props.name} className={props.className} min={props.min} max={props.max} placeholder={props.placeholder} type="number" /> : null }
            { props.error ? <img className={props.input === 'textarea' ? 'textarea-icon' : ''} onClick={iconClicked} src="/info-icon-error.png" alt="error" /> : <img className={props.input === 'textarea' ? 'textarea-icon' : ''} onClick={iconClicked} src="/info-icon-blue.png" alt="info" /> }
        </div>

        { props.labelText && props.labelFontWeight !== 'bold' ? <label htmlFor={props.labelHtml}>{props.labelText}</label> : null }
        { props.labelText && props.labelFontWeight === 'bold' ? <label htmlFor={props.labelHtml}><strong>{props.labelText}</strong> </label> : null }

        <div className="helper-text-container">
            <span className="close" onClick={() => {setActive(false)}}></span>
            <p className="helper-text">{props.text}</p>
        </div>

    </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        formItemFocus: () => dispatch(formItemFocus()),
        formItemBlur: () => dispatch(formItemBlur())
    }
}

export default connect(null, mapDispatchToProps)(InputInfoContainer)