import React from 'react';

const SwiperNav = props => {
    return (
        <div ref={props.swiperNavRef} className="swiper-nav-wrapper">
            <div className={"swiper-nav-container" + (props.showNav ? '' : ' hide')}>
                <div className={"button-container back-container" + (props.activeBack ? ' active' : '') + (props.hiddenBack ? ' hide' : '')}>
                    <button className="swiper-buttton back-button" onClick={(e) => {props.goPrev(e)}}><span id="back" className="arrow"></span></button>
                </div>

                <div className={"button-container next-container" + (props.activeNext ? ' active' : '')}>
                    <button className="swiper-button next-button" onClick={(e) => {props.nextClicked(e)}}><span id="next" className={props.nextButtonClass}></span></button>
                </div>
            </div>
        </div>
    )
}

export default SwiperNav