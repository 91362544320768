import React from 'react';

const Copyright = props => {

    return (
        <div className="copyright-wrapper">
            <p>Designed & ©</p>
            <img src="/amurabi-logo.png" alt="amurabi logo"/>
        </div>
    )
}

export default Copyright