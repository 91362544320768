import React from 'react';
import ReactDOM from 'react-dom';

const ModalContainer = props => props.modalShowing ? ReactDOM.createPortal(
  <>
    <div className="modal-overlay"></div>
    <div className={"modal-wrapper" + (props.active ? ' opened' : '')}>
        <div className="modal-container">
            <div className="viewport">
                <div className="inner">
                    <div className="content">
                        <h2>Welcome,</h2>
                        <p>Please enter the password you received in the email, to start filling and sign your Confidentiality Agreement</p>
                
                        <div className="form-item">
                            <input className={props.error ? ' error' : ''} onChange={props.handleChange} value={props.value} name="password" type="password" placeholder="Enter your password here"/>
                        </div>

                        <button onClick={props.onClick} className="rounded-edge-button modal">Let's start!</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </>, 
    document.getElementById('modal-root')
) : null;

export default ModalContainer;