import { useState, useEffect } from "react";
import axios from "axios";
import { config } from "../services";

import { isMobile } from "react-device-detect";

const UseFormInput = (callback) => {
  let [values, setValues] = useState({});
  let [tempDate, setTempDate] = useState("");
  let [emailErr, setEmailErr] = useState({
    emailA: true,
    emailB: true,
  });
  let [emailErrSign, setEmailErrSign] = useState({
    emailASign: true,
    emailBSign: true,
  });
  let [loading, setLoading] = useState(false);
  let [modalShowing, setModalShowing] = useState(false);
  let [password, setPassword] = useState("");
  let [passwordError, setPasswordError] = useState(false);
  let [partyBReturn, setPartyBReturn] = useState(false);
  let path = window.location.pathname;

  // FIRST SECTION IS FOR THE FORM, SECOND IS FOR PASSWORD (password is used for when the user returns)

  // FORM
  // if startDate or tempDate are an object and duration is filled (i.e, if they are a date object) call display end date above
  const displayEndDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    setValues((values) => ({
      ...values,
      endDate: new Date(year + parseInt(values.duration, 10), month, day),
    }));
  };

  // fetch stored form and display on form
  useEffect(() => {
    if (path !== "/") {
      setModalShowing(true);
    }
  }, [path]);

  // calculate date
  useEffect(() => {
    // desktop
    if (typeof values.startDate === "object" && values.duration) {
      displayEndDate(values.startDate);
    }
    // mobile
    if (typeof tempDate === "object" && values.duration) {
      displayEndDate(tempDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.startDate, values.duration, tempDate]);

  // check if email has an error
  useEffect(() => {
    if (values.emailA) {
      let emailMatch = values.emailA
        .toLowerCase()
        .match(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        );

      if (emailMatch === null) {
        setEmailErr((values) => ({ ...values, emailA: true }));
      } else if (emailMatch || emailMatch === "") {
        setEmailErr((values) => ({ ...values, emailA: false }));
      }
    }

    if (values.emailB) {
      let emailMatch = values.emailB
        .toLowerCase()
        .match(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        );

      if (emailMatch === null) {
        setEmailErr((values) => ({ ...values, emailB: true }));
      } else if (emailMatch) {
        setEmailErr((values) => ({ ...values, emailB: false }));
      }
    }

    if (values.emailASign) {
      let emailMatch = values.emailASign
        .toLowerCase()
        .match(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        );

      if (emailMatch === null) {
        setEmailErrSign((values) => ({ ...values, emailASign: true }));
      } else if (emailMatch || emailMatch === "") {
        setEmailErrSign((values) => ({ ...values, emailASign: false }));
      }
    }

    if (values.emailBSign) {
      let emailMatch = values.emailBSign
        .toLowerCase()
        .match(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        );

      if (emailMatch === null) {
        setEmailErrSign((values) => ({ ...values, emailBSign: true }));
      } else if (emailMatch) {
        setEmailErrSign((values) => ({ ...values, emailBSign: false }));
      }
    }
  }, [
    values.emailA,
    values.emailB,
    values.emailASign,
    values.emailBSign,
    emailErr.emailA,
    emailErr.emailB,
    emailErrSign.emailASign,
    emailErrSign.emailBSign,
  ]);

  // puts start date into date object so that the end date can be calculated
  const handleStartDateMobile = (event) => {
    setTempDate(new Date(event.target.value));
  };

  // for the date picker (since the event is already a date object)
  const handleStartDateDesk = (event) => {
    setValues((values) => ({ ...values, startDate: event }));
  };

  // as the user is typing/makes a change in the form
  const handleFormChange = (event) => {
    if (event.persist) event.persist();

    if (event.target.type === "date") {
      handleStartDateMobile(event);
    }

    let name = event.target.name;
    // if party b is writing on the form, then do not allow them to change what party a wrote before
    if (partyBReturn) {
      if (
        name === "compNameA" ||
        name === "compTypeA" ||
        name === "nationalityA" ||
        name === "addressA" ||
        name === "repNameA" ||
        name === "positionA" ||
        name === "contactNameA" ||
        name === "emailA" ||
        name === "mobileA" ||
        name === "contactNameASign" ||
        name === "emailASign"
      )
        return;
    }

    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  // PASSWORD
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    if (passwordError) setPasswordError(false);
  };

  // when the user submits their password
  const checkPassword = () => {
    setLoading(true);

    axios
      .get(config.apiPath + config.apiGetContract + path + "/" + password)
      .then((response) => {
        setLoading(false);
        let data = response.data;

        let endDate = "";
        if (data.endDate !== null) endDate = new Date(data.endDate);

        let startDate = "";
        if (data.startingDate && isMobile)
          startDate = data.startingDate.split("T")[0];
        else if (data.startingDate && !isMobile)
          startDate = new Date(data.startingDate);

        let partyAaddress = "";
        let partyBaddress = "";
        if (data.partyAinfo.address)
          partyAaddress = data.partyAinfo.address.includes("↵")
            ? data.partyAinfo.address.replace(/↵/g, "\n")
            : data.partyAinfo.address;

        if (data.partyBinfo.address)
          partyBaddress = data.partyBinfo.address.includes("↵")
            ? data.partyBinfo.address.replace(/↵/g, "\n")
            : data.partyBinfo.address;

        setValues({
          addressA: partyAaddress,
          addressB: partyBaddress,
          compNameA: data.partyAinfo.company,
          compNameB: data.partyBinfo.company,
          compTypeA: data.partyAinfo.companyType,
          compTypeB: data.partyBinfo.companyType,
          confidentialInfoTypes: data.informationIncluded,
          contactNameA: data.partyAcontact.name,
          contactNameB: data.partyBcontact.name,
          contactNameASign: data.partyAsignature.name,
          contactNameBSign: data.partyBsignature.name,
          courts: data.courtJurisdiction,
          duration: data.durationProject,
          emailA: data.partyAcontact.email,
          emailB: data.partyBcontact.email,
          emailASign: data.partyAsignature.email,
          emailBSign: data.partyBsignature.email,
          endDate: endDate,
          legalRegulations: data.applicableLaw,
          mobileA: data.partyAsignature.phone,
          mobileB: data.partyBsignature.phone,
          nationalityA: data.partyAinfo.country,
          nationalityB: data.partyBinfo.country,
          noticeExpDate: data.daysPriorNotice,
          numDays: data.daysAfterDeclarationDispute,
          positionA: data.partyAinfo.position,
          positionASign: data.partyAsignature.position,
          positionB: data.partyBinfo.position,
          positionBSign: data.partyBsignature.position,
          projectName: data.project,
          repNameA: data.partyAinfo.name,
          repNameB: data.partyBinfo.name,
          startDate: startDate,
        });
        setModalShowing(false);
        setPartyBReturn(true);
      })
      .catch((error) => {
        setLoading(false);
        setPasswordError(true);
      });
  };

  return {
    handleFormChange,
    handleStartDateDesk,
    values,
    emailErr,
    emailErrSign,
    loading,
    setLoading,
    modalShowing,
    handlePasswordChange,
    password,
    checkPassword,
    passwordError,
    partyBReturn,
  };
};

export default UseFormInput;
