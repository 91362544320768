import React, { useState, useEffect, useRef } from 'react'
import SwiperNav from './SwiperNav.js'

import { connect } from 'react-redux';

import { stageSwitch, formSwitch, activeStart, activeFillInfo, activeReadCarefully, activeSignature, activeAgreement, switchToContract, contractSectionForm, switchToAgreement, userClickedNextInputsOnPage, resetUserClickedNextInputsOnPage, inputsOnPage } from '../redux';
import { isMobileSafari } from 'react-device-detect';

function SwiperNavContainer(props) {
    // action for each stage (which goes to the new section and )
    const stages = [
        {stage: '1', action: () => {return (swiper.slideTo(0), props.stageSwitch('1'), props.activeStart(), props.formSwitch('1'))}},
        {stage: '2.1', action: () => {return (swiper.slideTo(1), props.stageSwitch('2.1'), props.activeFillInfo(), props.formSwitch('2.1'))}},
        {stage: '2.2', action: () => {return (swiper.slideTo(2), props.stageSwitch('2.2'), props.activeFillInfo(), props.formSwitch('2.2'))}},
        {stage: '3.1', action: () => {return (swiper.slideTo(3), props.stageSwitch('3.1'), props.activeReadCarefully(), props.formSwitch('3.1'))}},
        {stage: '3.2', action: () => {return (swiper.slideTo(4), props.stageSwitch('3.2'))}},
        {stage: '3.3', action: () => {return  (swiper.slideTo(5), props.stageSwitch('3.3'))}},
        {stage: '3.4', action: () => {return (swiper.slideTo(6), props.stageSwitch('3.4'), props.formSwitch('3.4'))}},
        {stage: '3.5', action: () => {return (swiper.slideTo(7), props.stageSwitch('3.5'))}},
        {stage: '3.6', action: () => {return (swiper.slideTo(8), props.stageSwitch('3.6'))}},
        {stage: '3.7', action: () => {return (swiper.slideTo(9), props.stageSwitch('3.7'), props.formSwitch('3.7'))}},
        {stage: '3.8', action: () => {return (swiper.slideTo(10), props.stageSwitch('3.8'))}},
        {stage: '3.9', action: () => {return (swiper.slideTo(11), props.stageSwitch('3.9'), props.activeReadCarefully())}},
        {stage: '4', action: () => {return (swiper.slideTo(12), props.stageSwitch('4'), props.activeSignature(), props.formSwitch('4'))}},
        {stage: '5', action: () => {return (swiper.slideTo(13), props.stageSwitch('5'), props.activeAgreement(), props.postPutForm())}}
    ]

    // set and remove active class on back and next buttons
    let [activeBack, setActiveBack] = useState('')
    let [activeNext, setActiveNext] = useState('')
    let [copyrightActive, setCopyrightActive] = useState(false)
    let [showNav, setShowNav] =  useState(true)
    let swiper = props.swiper
    let slideToId = props.slideToId
    let swiperNavRef = useRef()

    let reduxStage = props.stageData.stage
    const inputsOnPage = reduxStage === '2.1' || reduxStage === '2.2' || reduxStage === '3.1' || reduxStage === '3.4' || reduxStage === '3.7' || reduxStage === '4'


    // hide swiper nav on form input focus
    useEffect (() => {
        if (props.formData.formItem === 'focus') 
            setShowNav(false)
        else 
            setShowNav(true)
      }, [props.formData.formItem])

    //   show different sections when going from desktop to mobile and mobile to desktop
    useEffect(() => {
        const handleResize = event => {
            if(props.formData.resizedTo !== 'mobile' && window.innerWidth <= 600) {
                 // read carefully active
                if(props.stageData.navTwo === 'active') {
                    stages[1].action()
                // fill in information active
                } else if(props.stageData.navThree === 'active') {
                    stages[3].action()
                // signature active
                } else if(props.stageData.navFour === 'active') {
                    stages[12].action()
            }
            // changing from mobile to desktop
            } else if (props.formData.resizedTo === 'desktop' && window.innerWidth > 600 ) {
                if(reduxStage !== '1') {
                    props.stageSwitch('1')
                    props.switchToContract()
                    props.contractSectionForm()
                    // scroll to
                    // read carefully
                    if(props.stageData.navTwo ===  'active') {
                        const yOffset = -60; 
                        window.scrollTo({top: props.fillInfoStart.current.getBoundingClientRect().top + window.pageYOffset  + yOffset}); 
                        swiper.slideTo(0)  
                    // signature
                    } else if (props.stageData.navThree ===  'active') {
                        window.scrollTo({top: props.readCarefulStart.current.getBoundingClientRect().top + window.pageYOffset + 210}); 
                        swiper.slideTo(0)
                    // signature
                    } else if (props.stageData.navFour ===  'active') {
                        const yOffset = -80; 
                        window.scrollTo({top: props.signatureStart.current.getBoundingClientRect().top + window.pageYOffset + yOffset}); 
                        swiper.slideTo(0)
                    // if on agreement (final stage)
                    } else if (props.stageData.navFive === 'active') {
                        swiper.slideTo(0)
                        props.switchToAgreement()
                    }
                }
            }
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.stageData.contractActive, props.formData.resizedTo, stages, props.stageData.navTwo, props.stageData.navThree, props.stageData.navFour])
    
    // go to next stage
    const nextClicked = event => {
        event.preventDefault()

        // give next button its animation
        setActiveNext(true)
        setTimeout(()=> {setActiveNext(false)}, 300)

        // the error class itself is displayed because when the user clicks the next button, the userClickedNextOnInputsPage is dispatched and set to true in redux
        // this is so that the user only sees an error once they have clicked the next button
        // see className on form-items or error-input-wrap (in app) to see the ternerary operator logic
        if(inputsOnPage)
            props.userClickedNextInputsOnPage()

        // if there are no inputs on the page then always allow next
        if(!props.formData.inputsOnPage) {
            // go through each stage in the stages array above and find the index number that matches the redux stage
            const currentIndex = stages.findIndex((el) => el.stage === reduxStage)
            // return the action of the next stage
            return (
                stages[currentIndex + 1].action()
            )
        }

        // if user can go next and there are inputs on the page
        if(props.formData.userCanGoNext && props.formData.inputsOnPage) {
            if (isMobileSafari) 
                document.getElementsByTagName('html')[0].scrollTo(0, 0)

            document.getElementById('inner-active').scrollTo(0,0)
            props.resetUserClickedNextInputsOnPage()
            // if the user has reached the end stage but they have clicked 'next' return the user to step 1
            if (reduxStage === '5') {
                return( 
                    stages[0].action(),
                    window.location.reload()
                )
            // if the application is on stage 1 (start), then move to stage 2.1
            } else if (reduxStage === '1') {
                return ( 
                    stages[1].action()
                )
            // if the application is on stage 4 (sign), then move to stage 5    
            } else if (reduxStage === '4') {
                return ( 
                    stages[13].action()
                )
            } else {
                // go through each stage in the stages array above and find the index number that matches the redux stage
                const currentIndex = stages.findIndex((el) => el.stage === reduxStage)
                // return the action of the next stage
                return (
                    stages[currentIndex + 1].action()
                )
            }
        }
    }
                
    // go to previous stage
    const goPrev = event => {
        event.preventDefault()
        
        props.resetUserClickedNextInputsOnPage()

        if (isMobileSafari) 
            document.getElementsByTagName('html')[0].scrollTo(0, 0)

        document.getElementById('inner-active').scrollTo(0,0)

        setActiveBack(true)
        setTimeout(()=> {setActiveBack(false)}, 300)

         // if the user has reached the end stage but they have clicked 'next' return the user to step 1
        if (reduxStage === '5') {
            return stages[12].action()
        } else if (reduxStage === '2.1') {
            return stages[0].action()
        // same as goToNext but instead dispatches action of previous index
        } else {
            // go through each stage in the stages array above and find the index number that matches the redux stage
            const currentIndex = stages.findIndex((el) => el.stage === reduxStage)
            // return the action of the previous stage
            return stages[currentIndex - 1].action()
        }
    }

    const handleCopyrightMobileScroll = event => {
        const innerActive = document.getElementById('inner-active')
        console.log(innerActive.getBoundingClientRect().bottom, 'bottom')
        console.log(innerActive.scrollHeight, 'scroll height')
        console.log(event)
        if(innerActive.getBoundingClientRect().bottom === innerActive.scrollHeight && !copyrightActive) {
            setCopyrightActive(true)
        } else if (innerActive.getBoundingClientRect().bottom > innerActive.scrollHeight) {
            setCopyrightActive(false)
        }
    }

    //  handlescroll for copyrightt on mobile
    useEffect(() => {
        if (reduxStage === '1' || reduxStage === '5') {
            window.addEventListener('scroll', handleCopyrightMobileScroll);
            return () => window.removeEventListener('scroll', handleCopyrightMobileScroll);
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reduxStage])

    // if user clicks header, then user slides back to this page (mobile version)
    useEffect(() => {
        if(slideToId === 'start-prev-header')
            stages[0].action()
        else if (slideToId === 'fill-info-prev-header')
            stages[1].action()
        else if (slideToId === 'read-carefully-prev-header') 
            stages[3].action()        
        else if (slideToId === 'sign-prev-header')
            stages[12].action()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slideToId, swiper])

    // nextDisabled={props.formData.nextDisabled} 
    return (
        <>
            <SwiperNav activeNext={activeNext} activeBack={activeBack} hiddenBack={props.stageData.backButtonHide ? true : false} swiperNavRef={swiperNavRef} goPrev={(e) => goPrev(e)} nextClicked={(e) => nextClicked(e)} nextButtonClass={props.stageData.nextButtonClass} showNav={showNav} />
        </>
    )
}

function mapStateToProps(state) {
    return { 
        stageData: state.stage,
        formData: state.form
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        stageSwitch: newStage => dispatch(stageSwitch(newStage)),
        formSwitch: newStage => dispatch(formSwitch(newStage)),
        activeStart: () => dispatch(activeStart()),
        activeFillInfo: () => dispatch(activeFillInfo()),
        activeReadCarefully: () => dispatch(activeReadCarefully()),
        activeSignature: () => dispatch(activeSignature()),
        activeAgreement: () => dispatch(activeAgreement()),
        switchToContract: () => dispatch(switchToContract()),
        contractSectionForm: () => dispatch(contractSectionForm()),
        switchToAgreement: () => dispatch(switchToAgreement()),
        userClickedNextInputsOnPage: () => dispatch(userClickedNextInputsOnPage()),
        resetUserClickedNextInputsOnPage: () => dispatch(resetUserClickedNextInputsOnPage()),
        inputsOnPage: () => dispatch(inputsOnPage())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SwiperNavContainer)
