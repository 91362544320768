const scrollToTop = () => {
    // go to top of page
    return window.scrollTo(0, 0)
}

let config = {
    apiPath: process.env.REACT_APP_API_URL,
    // apiPath: 'http://163.172.108.49:3030',
    // apiPath: 'http://localhost:3031',
    apiCreateContract: '/create-contract',
    apiGetContract: '/get-contract',
    apiUpdateContract: '/update-contract'
}

export { config, scrollToTop }